import { CreditYears } from './credit';
import { startOfMonth, endOfMonth, startOfDay, startOfYear, endOfYear, sub, endOfDay } from 'date-fns';
export function getDefaultRanges() {
    const today = startOfDay(new Date());
    const yesterday = sub(today, { days: 1 });
    const oneMonthAgo = sub(today, { months: 1 });
    return {
        today: [today, endOfDay(today)],
        yesterday: [yesterday, endOfDay(yesterday)],
        this_month: [startOfMonth(today), endOfMonth(today)],
        last_month: [startOfMonth(oneMonthAgo), endOfMonth(oneMonthAgo)],
        this_year: [startOfYear(today), endOfYear(today)],
    };
}
;
export function getCreditYearRanges() {
    return CreditYears.reduce((ranges, year) => {
        const date = new Date(year, 0, 1);
        ranges[year] = [startOfYear(date), endOfYear(date)];
        return ranges;
    }, {});
}
