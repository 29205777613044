import λ from '~/utils/invoker';
import { getId } from '#/object-id';
import ListMixin from '~/mixins/list';
import { routeNames } from '~/router';
import PermissionMixin from '~/mixins/permission';
import PaginatedTable from '~/components/paginated-table.vue';
import ConfirmDialog from '~/components/confirm-dialog.vue';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import { mdiTrashCanOutline, mdiPlusCircleOutline, mdiMagnify, mdiDownload } from '@mdi/js';
import ParticipationFilters from './participation-filters.vue';
import { endOfYear, startOfYear } from 'date-fns';
import { toLocalDate } from '#/date-fns-utils';
import { path, prop, uniq } from 'ramda';
const participationsλ = λ.namespaced('participations');
const servicesλ = λ.namespaced('services');
const trainingsλ = λ.namespaced('trainings');
const getUpdateFilterWatcher = property => {
    return {
        handler(value) {
            this.$set(this.query.filters, property, value);
        },
        immediate: true,
    };
};
export default {
    name: 'participation-list',
    mixins: [ListMixin('participations'), PermissionMixin],
    components: {
        ConfirmDialog,
        GoBackWrapper,
        PaginatedTable,
        ParticipationFilters,
    },
    props: {
        service: String,
        training: String,
        agency: String,
    },
    data() {
        const base = new Date(this.$store.state.year, 0, 1);
        return {
            mdiMagnify,
            mdiTrashCanOutline,
            mdiPlusCircleOutline,
            mdiDownload,
            query: {
                sortBy: 'session.date',
                sortDesc: false,
                page: 1,
                itemsPerPage: 10,
                filters: {
                    range: {
                        start: startOfYear(base),
                        end: endOfYear(base),
                    }
                },
            },
        };
    },
    watch: {
        service: getUpdateFilterWatcher('service'),
        training: getUpdateFilterWatcher('training'),
        agency: getUpdateFilterWatcher('agency'),
        allParticipations: {
            handler(value) {
                if (this.canListServices) {
                    const services = uniq(value.map(prop('service'))
                        .filter(id => undefined === this.allServicesById[id]));
                    if (services.length) {
                        this.amendAllServices({ filters: { id: services } });
                    }
                }
                if (this.canListTrainings) {
                    const trainings = uniq(value.map(path(['session', 'training']))
                        .filter(id => undefined === this.allTrainingsById[id]));
                    if (trainings.length) {
                        this.amendAllTrainings({ filters: { id: trainings } });
                    }
                }
            },
            immediate: true,
        },
    },
    computed: {
        allServicesById: servicesλ.getter('allById'),
        allTrainingsById: trainingsλ.getter('allById'),
        allParticipations: participationsλ.state('all'),
        totalCount: participationsλ.state('totalCount'),
        headers() {
            return [
                { text: this.$tc('services.title', 1), value: 'service', sortable: false },
                { text: this.$tc('trainings.title', 1), value: 'session.training', sortable: false },
                { text: this.$t('session.date'), value: 'session.date' },
                { text: this.$t('participation.present'), value: 'present' },
                { text: this.$t('participation.excused'), value: 'excused' },
                { text: this.$t('participation.absent'), value: 'absent' },
                { text: this.$t('Used Credit'), value: 'credits' },
                ...(this.canDeleteParticipations ? [{ text: this.$t('Delete'), sortable: false, }] : []),
            ];
        },
        goBackTo() {
            var _a;
            return ((_a = this.$route.params) === null || _a === void 0 ? void 0 : _a.goBackTo) || '/';
        },
    },
    methods: {
        toLocalDate,
        amendAllServices: servicesλ.action('amendAll'),
        amendAllTrainings: trainingsλ.action('amendAll'),
        remove: participationsλ.action('remove'),
        downloadExport: participationsλ.action('downloadExport'),
        openItem(item) {
            if (this.canReadParticipations) {
                this.$router.push({
                    name: routeNames.participations.edit,
                    params: { id: getId(item) }
                });
            }
        },
        addNewItem() {
            this.$router.push({
                name: routeNames.participations.create,
            });
        },
        removeParticipation(participation) {
            this.$refs.deleteDialog
                .open()
                .then(async (doDelete) => {
                if (doDelete) {
                    await this.remove(participation);
                }
            });
        },
        displayService(service) {
            var _a, _b;
            return service.name || ((_b = (_a = this.allServicesById) === null || _a === void 0 ? void 0 : _a[getId(service)]) === null || _b === void 0 ? void 0 : _b.name) || service;
        },
        displayTraining(training) {
            var _a, _b;
            return training.name || ((_b = (_a = this.allTrainingsById) === null || _a === void 0 ? void 0 : _a[getId(training)]) === null || _b === void 0 ? void 0 : _b.name) || training;
        },
    },
};
