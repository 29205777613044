import λ from '~/utils/invoker';
import { filter } from 'ramda';
import ItemSearchSelector from '~/components/item-search-selector.vue';
import DateRangePicker from '~/components/date-range-picker.vue';
import { endOfYear, startOfYear } from 'date-fns';
import { getDefaultRanges, getCreditYearRanges } from '#/date-defaults';
import { renameKeysWith as mapKeys } from 'ramda-adjunct';
export default {
    name: 'participation-filters',
    components: {
        ItemSearchSelector,
        DateRangePicker,
    },
    model: {
        prop: 'filters',
    },
    props: {
        filters: Object,
        canListServices: Boolean,
        canListTrainings: Boolean,
        canListAgencies: Boolean,
    },
    watch: {
        selectedYear() {
            this.$nextTick(() => {
                this.$refs['date-range-picker'].reset();
            });
        },
    },
    computed: {
        selectedYear: λ.vuexState('year'),
        defaultRange() {
            const base = new Date(this.selectedYear, 0, 1);
            return {
                startDate: startOfYear(base),
                endDate: endOfYear(base),
            };
        },
        presetRanges() {
            return {
                ...mapKeys(key => this.$t(key), getDefaultRanges()),
                ...getCreditYearRanges(),
            };
        },
    },
    methods: {
        onInput() {
            this.$emit('input', filter(Boolean, this.filters));
        },
    },
};
