import { last } from 'ramda';
import DateRange from 'vue2-daterange-picker';
import { mdiCalendarRange, mdiReload } from '@mdi/js';
import { isDate, isValid } from 'date-fns';
import { toLocalDate } from '#/date-fns-utils';
import { getDefaultRanges } from '#/date-defaults';
import { renameKeysWith as mapKeys } from 'ramda-adjunct';
export default {
    name: 'date-range-picker',
    components: {
        DateRange,
    },
    model: {
        prop: 'range',
    },
    props: {
        range: Object,
        ranges: Object,
        defaultRange: Object,
        clearable: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            mdiCalendarRange,
            mdiReload,
            undefinedRange: { startDate: null, endDate: null },
            pickerLocale: {
                direction: 'ltr',
                format: 'yyyy-mm-dd',
                separator: ' - ',
                applyLabel: this.$t('Confirm'),
                cancelLabel: this.$t('Cancel'),
                weekLabel: this.$t('week_label'),
                customRangeLabel: '',
            },
        };
    },
    mounted() {
        var _a;
        if (!this.hasRange) {
            this.pickerRange = (_a = this.defaultRange) !== null && _a !== void 0 ? _a : undefined;
        }
    },
    computed: {
        hasRange() {
            return this.range && isDate(this.range.start) && isDate(this.range.end);
        },
        displayRange: {
            get() {
                return `${this.formatDate(this.pickerRange.startDate)} - ${this.formatDate(this.pickerRange.endDate)}`;
            },
            set(value) {
                if (!value) {
                    this.pickerRange = this.undefinedRange;
                }
            },
        },
        pickerRange: {
            get() {
                return this.hasRange
                    ? { startDate: this.range.start, endDate: this.range.end }
                    : this.undefinedRange;
            },
            set(range) {
                if (isValid(range.startDate) && isValid(range.endDate)) {
                    this.$emit('input', { start: range.startDate, end: range.endDate });
                    return;
                }
                this.$emit('input', undefined);
            },
        },
        presetRanges() {
            var _a;
            return (_a = this.ranges) !== null && _a !== void 0 ? _a : mapKeys(key => this.$t(key), getDefaultRanges());
        },
    },
    methods: {
        reset() {
            var _a;
            this.pickerRange = (_a = this.defaultRange) !== null && _a !== void 0 ? _a : last(Object.values(this.presetRanges));
        },
        formatDate(date) {
            return isValid(date) ? toLocalDate(date) : '';
        },
    }
};
